import { FormEventHandler, useEffect } from "react"

import { Head, Link, useForm } from "@inertiajs/react"

import Checkbox from "@/Components/Checkbox"
import InputError from "@/Components/InputError"
import InputLabel from "@/Components/InputLabel"
import WhrcLogoOnDark from "@/Components/Logos/WhrcLogoOnDark"
import WhrcLogoOnLight from "@/Components/Logos/WhrcLogoOnLight"
import PrimaryButton from "@/Components/PrimaryButton"
import TextInput from "@/Components/TextInput"

export default function Login({
  status,
  canResetPassword,
}: {
  canResetPassword: boolean
  status?: string
}) {
  const { data, setData, post, processing, errors, reset } = useForm({
    email: "",
    password: "",
    remember: false,
  })

  const submit: FormEventHandler = (e) => {
    e.preventDefault()

    post(route("login"))
  }

  return (
    <>
      <div className="space-y-16">
        <Head title="Sign in" />
        <div>
          <div className="my-16 flex items-center justify-center">
            <Link href="/">
              <WhrcLogoOnLight className="w-32 fill-current text-cerulean-500 sm:w-96" />
            </Link>
          </div>
        </div>

        <div>
          {status && (
            <div className="mb-4 text-sm font-medium text-green-600">
              {status}
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="text-xl font-medium leading-7">Sign In</div>
          </div>

          <form onSubmit={submit}>
            <div>
              <InputLabel htmlFor="email" value="Email" />

              <TextInput
                id="email"
                type="email"
                name="email"
                value={data.email}
                className="mt-1 block w-full"
                autoComplete="username"
                isFocused={true}
                onChange={(e) => setData("email", e.target.value)}
              />

              <InputError message={errors.email} className="mt-2" />
            </div>

            <div className="mt-4">
              <InputLabel htmlFor="password" value="Password" />

              <TextInput
                id="password"
                type="password"
                name="password"
                value={data.password}
                className="mt-1 block w-full"
                autoComplete="current-password"
                onChange={(e) => setData("password", e.target.value)}
              />

              <InputError message={errors.password} className="mt-2" />
            </div>

            <div className="mt-4 block">
              <label className="flex items-center">
                <Checkbox
                  name="remember"
                  checked={data.remember}
                  onChange={(e) => setData("remember", e.target.checked)}
                />
                <span className="ml-2 text-sm text-gray-600">Remember me</span>
              </label>
            </div>

            <div className="mt-4 flex items-center justify-end">
              {canResetPassword && (
                <Link
                  href={route("password.request")}
                  className="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-cerulean-500 focus:ring-offset-2"
                >
                  Forgot your password?
                </Link>
              )}

              <PrimaryButton className="ml-4" disabled={processing}>
                Log in
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
